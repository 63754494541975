import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'SPIDEY-BUSD LP',
    lpAddresses: {
      97: '0x3B28be8f06aC700d15d573823c1dc9733BA97B7f',
      56: '0xcE37B50B2538C648a251AdC48f290b80F6bc1C2c',
    },
    tokenSymbol: 'SPIDEY',
    tokenAddresses: {
      97: '0x5F1cBBE0C9963c83bf046F1A53ECe316ea6E8cC8',
      56: '0xE427bb1d14553f6DE5FE64466cdf69669572A54b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'SPIDEY-BNB LP',
    lpAddresses: {
      97: '0xCcD01c99D2B80613dbAa023004C3a6D8Caff40dB',
      56: '0x14887aa4CEBBaefeC7b91f9fC339E2840eCE0379',
    },
    tokenSymbol: 'SPIDEY',
    tokenAddresses: {
      97: '0x5F1cBBE0C9963c83bf046F1A53ECe316ea6E8cC8',
      56: '0xE427bb1d14553f6DE5FE64466cdf69669572A54b',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'SPIDEY',
    lpAddresses: {
      97: '0x3B28be8f06aC700d15d573823c1dc9733BA97B7f',
      56: '0xcE37B50B2538C648a251AdC48f290b80F6bc1C2c', // CAKE-BUSD LP
    },
    tokenSymbol: 'SPIDEY',
    tokenAddresses: {
      97: '0x5F1cBBE0C9963c83bf046F1A53ECe316ea6E8cC8',
      56: '0xE427bb1d14553f6DE5FE64466cdf69669572A54b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
