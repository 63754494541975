export default {
  cake: {
    56: '0xE427bb1d14553f6DE5FE64466cdf69669572A54b',
    97: '0x5F1cBBE0C9963c83bf046F1A53ECe316ea6E8cC8',
  },
  masterChef: {
    56: '0xBece53164981D71789a636d2C8b45d6706905685',
    97: '0xDb1Fd2f0f5Baf77A963A066055249eBA18fadd80',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
}
